import React, { useState } from 'react';
import { Box, Container, Stepper, Step, StepLabel, Alert } from '@mui/material';
import logo from '../../images/logo.png'
import { PatientInfo } from './components/patientInfo';
import { Communication } from './components/communication';
import { DateTime } from "luxon";
import _ from 'lodash';
import { Ancestry } from './components/ancestry';
import { Identity } from './components/identification';
import { Terms } from './components/terms';
import { Confirm } from './components/confirm';

export const Enrollment = () => {
    const steps = ['Patient infomation', 'Communication & Emergency contact', 'Ancestry & ethnicity', 'Identification', 'Terms & Eligibility'];
    const [errorMessage, setErrorMessage] = useState(null);
    const [formData, setFormdata] = useState({
        familyName: {
            data: '',
            label: 'Family name',
            error: false,
            required: true,
            page: 1,
        },
        givenNames: {
            data: '',
            label: 'Given Names',
            error: false,
            required: true,
            page: 1,
        },
        title: {
            data: 'Mr',
            label: 'Title',
            error: false,
            required: true,
            page: 1,
        },
        dateOfBirth: {
            data: DateTime.now(),
            label: 'Date of birth',
            error: false,
            required: true,
            page: 1,
        },
        gender: {
            data: 'Male',
            label: 'Gender',
            error: false,
            required: true,
            page: 1,
        },
        homeAddress: {
            data: '',
            label: 'Home address',
            error: false,
            required: true,
            page: 1,
        },
        postalAddress: {
            data: '',
            label: 'Postal address',
            error: false,
            required: false,
            page: 1,
        },
        homePhone: {
            data: '',
            label: 'Home phone',
            error: false,
            required: true,
            page: 1,
        },
        mobileNumber: {
            data: '',
            label: 'Mobile number',
            error: false,
            required: false,
            page: 1,
        },
        email: {
            data: '',
            label: 'Email address',
            error: false,
            required: true,
            page: 1,

        },
        occupation: {
            data: '',
            label: 'Occupation',
            error: false,
            required: true,
            page: 1,
        },
        employer: {
            data: '',
            label: 'Employer',
            error: false,
            required: false,
            page: 1,
        },
        workPhone: {
            data: '',
            label: 'Work Phone Number',
            error: false,
            required: false,
            page: 1,
        },
        NHINumber: {
            data: '',
            label: 'NHI Number',
            error: false,
            required: false,
            page: 1,
        },
        smokingStatus: {
            data: 'Never smoked',
            label: 'Smoking status',
            error: false,
            required: true,
            page: 1,
        },
        smokingStatusQuit: {
            data: 'Yes',
            label: 'Smoking Assistance',
            error: false,
            required: false,
            page: 1,
        },
        smokingStatusTime: {
            data: '',
            label: 'How long did you smoke for',
            error: false,
            required: false,
            page: 1,
        },
        smokingStatusStop: {
            data: '',
            label: 'What year did you stop',
            error: false,
            required: false,
            page: 1,
        },
        textMessageConsent: {
            data: 'Yes',
            label: 'Text message consent',
            error: false,
            required: true,
            page: 2,
        },
        emailMessageConsent: {
            data: 'Yes',
            label: 'Email message consent',
            error: false,
            required: true,
            page: 2,
        },
        MMHConsent: {
            data: 'Yes',
            label: 'Manage my Health consent',
            error: false,
            required: true,
            page: 2,
        },
        patientSurveyConsent: {
            data: 'Yes',
            label: 'Survey Consent',
            error: false,
            required: true,
            page: 2,
        },
        emergencyFullName: {
            data: '',
            label: 'Emergency contact full name',
            error: false,
            required: true,
            page: 2,
        },
        emergencyAddress: {
            data: '',
            label: 'Emergency contact address',
            error: false,
            required: true,
            page: 2,
        },
        emergencyNumber: {
            data: '',
            label: 'Emergency contact phone number',
            error: false,
            required: true,
            page: 2,
        },
        emergencyRelationship: {
            data: '',
            label: 'Emergency contact relationship to patient',
            error: false,
            required: true,
            page: 2,
        },
        countryBirth: {
            data: '',
            label: 'Country and place of Birth',
            error: false,
            required: true,
            page: 3,
        },
        nzCitizen: {
            data: 'Yes',
            label: 'Are you a New Zealand Citizen',
            error: false,
            required: true,
            page: 3,
        },
        nzPermanent: {
            data: 'Yes',
            label: 'Living in New Zealand on a permanent or long-term basis',
            error: false,
            required: true,
            page: 3,
        },
        ethnicity: {
            data: [],
            label: 'Ethnicity',
            error: false,
            required: true,
            page: 3,
        },
        otherEthnicity: {
            data: '',
            label: 'other Ethnicity',
            error: false,
            required: false,
            page: 3,
        },
        maoriAncestry: {
            data: 'Yes',
            label: 'Do you have Maori Ancestry',
            error: false,
            required: true,
            page: 3,
        },
        prefLanguage: {
            data: '',
            label: 'preferred language',
            error: false,
            required: false,
            page: 3,
        },
        requireInterpreter: {
            data: 'No',
            label: 'Do you require an interpreter',
            error: false,
            required: true,
            page: 3,
        },
        interpreterLanguage: {
            data: '',
            label: 'interpreter language',
            error: false,
            required: false,
            page: 3,
        },
        difficultyWith: {
            data: [],
            label: 'Additional difficulties',
            error: false,
            required: false,
            page: 3,
        },
        understandingHealth: {
            data: 'No',
            label: 'difficulty understanding aspects of your health',
            error: false,
            required: false,
            page: 3,
        },
        specialCard: {
            data: 'No',
            label: 'Community Services card',
            error: false,
            required: false,
            page: 4,
        },
        CSC: {
            data: '',
            label: 'Community Services card',
            error: false,
            required: false,
            page: 4,
        },
        CSCExpire: {
            data: DateTime.now(),
            label: 'Community Services card expiry',
            error: false,
            required: false,
            page: 4,
        },
        HUHC: {
            data: '',
            label: 'High Usage card',
            error: false,
            required: false,
            page: 4,
        },
        HUHCExpire: {
            data: DateTime.now(),
            label: 'High Usage card expiry',
            error: false,
            required: false,
            page: 4,
        },
        files: {
            data: [],
            label: 'Photo ID',
            error: false,
            required: true,
            page: 4,
        },
        eligibility: {
            data: 'NZ Citizen',
            label: 'Eligibility',
            error: false,
            required: true,
            page: 4,
        },
        eligibilityOther: {
            data: '',
            label: 'Eligibility Other',
            error: false,
            required: false,
            page: 4,
        },
        confirmEligibility: {
            data: 'Yes',
            label: 'Confirm Eligibility',
            error: false,
            required: false,
            page: 5,
        },
        previousMedicalName: {
            data: '',
            label: 'Name of Previous Medical Centre',
            error: false,
            required: false,
            page: 5,
        },
        namePreviousGP: {
            data: '',
            label: 'Name of your GP at your previous medical centre',
            error: false,
            required: true,
            page: 5,
        },
        authoriseMedicalRecords: {
            data: '',
            label: 'Authorise Waimate Medical to obtain medical records from previous practice',
            error: false,
            required: true,
            page: 5,
        },
        signatureName: {
            data: '',
            label: 'Signature Name',
            error: false,
            required: true,
            page: 5,
        },
        signature: {
            data: '',
            label: 'Signature',
            error: false,
            required: true,
            page: 5,
        },
        signatureDate: {
            data: DateTime.now(),
            label: 'Signature date',
            error: false,
            required: true,
            page: 5,
        },
        signatureRelationship: {
            data: '',
            label: 'Signature Relationship',
            error: false,
            required: false,
            page: 5,
        }




    })
    const [activeStep, setActiveStep] = useState(0);

    const toNextPage = () => {
        // next page
        setActiveStep(activeStep + 1)
    }

    const backPage = () => {
        // next page
        setActiveStep(activeStep - 1)
    }


    const validation = (page) => {
        let emptyField = false;
        let label = '';

        _.forEach(formData, (d, key) => {
            if (_.isEmpty(d.data) && d.required && d.page === page) {
                emptyField = true;
                label = d.label;
                return false;
            }
        })
        //condition check
        if (formData.smokingStatus === 'Ex smoker') {
            if (_.isEmpty(formData.smokingStatusTime)) {
                setErrorMessage(`Please provide an answer for "How long did you smoke for?"`)
                emptyField = true;
            }
            if (_.isEmpty(formData.smokingStatusStop)) {
                setErrorMessage(`Please provide an answer for "What year did you stop?"`)
                emptyField = true
            }
        }
        if (!emptyField) {
            setErrorMessage(null)
            toNextPage()

        } else {
            setErrorMessage(`Please provide an answer for ${label}`)
        }
  

    }

    const renderStep = () => {
        if (activeStep === 0) {
            return <PatientInfo formData={formData} setFormData={setFormdata} nextPage={setActiveStep} currentPage={activeStep} validation={validation} />
        } else if (activeStep === 1) {
            return <Communication formData={formData} setFormData={setFormdata} nextPage={setActiveStep} currentPage={activeStep} validation={validation} back={backPage} />
        } else if (activeStep === 2) {
            return <Ancestry formData={formData} setFormData={setFormdata} nextPage={setActiveStep} currentPage={activeStep} validation={validation} back={backPage} />
        } else if (activeStep === 3) {
            return <Identity formData={formData} setFormData={setFormdata} nextPage={setActiveStep} currentPage={activeStep} validation={validation} back={backPage} />
        } else if (activeStep === 4) {
            return <Terms formData={formData} setFormData={setFormdata} nextPage={setActiveStep} currentPage={activeStep} validation={validation} back={backPage} />
        }else if (activeStep === 5) {
            return <Confirm formData={formData} setFormData={setFormdata} nextPage={setActiveStep} currentPage={activeStep} validation={validation} back={backPage} />
        }
    }
    return (
        <Container fluid='xl' sx={{ padding: 5 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 0 }}>
                <img src={logo} alt='logo' style={{ width: '400px' }} />
            </Box>
            <Box sx={{ width: '100%', textAlign: 'center', color: '#062F5D' }}>
                <h1>Patient Enrolment Form</h1>
            </Box>
            <Box>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {errorMessage ? <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, marginBottom: 3 }}><Alert severity="error">{errorMessage}</Alert></Box> : null}
                {renderStep()}
            </Box>
        </Container>
    )
}