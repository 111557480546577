import React from 'react';
import { InputBase, InputLabel, Grid, MenuItem, Select, TextField, Button, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { LocalizationProvider, DatePicker } from '@mui/lab';


const inputStyle = {
    border: '1px solid #C4C4C4',
    borderRadius: 3,
    height: '45px',
    width: '100%',
    paddingLeft: 2,
}

const selectStyle = {
    borderColor: '#C4C4C4',
    borderRadius: 3,
    height: '45px',
    width: '100%'
}

const labelStyle = {
    fontWeight: 'bold',
}

const dateStyle = {
    borderColor: '#C4C4C4',
    borderRadius: 3,
    height: '45px !important',
    width: '100%'
}

export const PatientInfo = ({ setFormData, formData, validation }) => {

    return (
        <Box sx={{ marginTop: 3 }}>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle} > Family Name </InputLabel>
                    <InputBase sx={inputStyle} value={formData.familyName.data} onChange={(e) => setFormData({ ...formData, familyName: { ...formData.familyName, data: e.target.value } })} />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle}> Given Names </InputLabel>
                    <InputBase sx={inputStyle} value={formData.givenNames.data} onChange={(e) => setFormData({ ...formData, givenNames: { ...formData.givenNames, data: e.target.value } })} />
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={3}>
                    <InputLabel sx={labelStyle}> Title</InputLabel>
                    <Select sx={selectStyle} value={formData.title.data} onChange={(e) => setFormData({ ...formData, title: { ...formData.title, data: e.target.value } })} >
                        <MenuItem value={'Mr'}>Mr</MenuItem>
                        <MenuItem value={'Mrs'}>Mrs</MenuItem>
                        <MenuItem value={'Ms'}>Ms</MenuItem>
                        <MenuItem value={'Miss'}>Miss</MenuItem>
                        <MenuItem value={'Dr'}>Dr</MenuItem>
                        <MenuItem value={'Other'}>Other</MenuItem>
                    </Select>
                </Grid>
                {
                    formData.title.data === 'Other' ?
                        <Grid item xs={3}>
                            <InputLabel sx={labelStyle}>Please specify</InputLabel>
                            <InputBase sx={inputStyle} value={formData.title.Other} onChange={(e) => setFormData({ ...formData, titleOther: { ...formData.titleOther, data: e.target.value } })} />
                        </Grid> : null
                }
                <Grid item xs={4}>
                    <InputLabel sx={labelStyle}> Date of Birth </InputLabel>
                    <LocalizationProvider dateAdapter={AdapterLuxon} locale={"en-NZ"}>
                        <DatePicker
                            sx={dateStyle}
                            locale
                            value={formData.dateOfBirth.data}
                            onChange={(e) => setFormData({ ...formData, dateOfBirth: { ...formData.dateOfBirth, data: e } })}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <InputLabel sx={labelStyle}> Gender </InputLabel>
                    <Select sx={selectStyle} value={formData.gender.data} onChange={(e) => setFormData({ ...formData, gender: { ...formData.gender, data: e.target.value } })}>
                        <MenuItem value={'Male'}>Male</MenuItem>
                        <MenuItem value={'Female'}>Female</MenuItem>
                        <MenuItem value={'Other'}>Other</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <InputLabel sx={labelStyle}> Home address </InputLabel>
                    <InputBase sx={inputStyle} value={formData.homeAddress.data} onChange={(e) => setFormData({ ...formData, homeAddress: { ...formData.homeAddress, data: e.target.value } })} />
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <InputLabel sx={labelStyle}> Postal address (if different from above) </InputLabel>
                    <InputBase sx={inputStyle} value={formData.postalAddress.data} onChange={(e) => setFormData({ ...formData, postalAddress: { ...formData.postalAddress, data: e.target.value } })} />
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle}> Home Phone Number </InputLabel>
                    <InputBase sx={inputStyle} value={formData.homePhone.data} onChange={(e) => setFormData({ ...formData, homePhone: { ...formData.homePhone, data: e.target.value } })} />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle}> Mobile Number</InputLabel>
                    <InputBase sx={inputStyle} value={formData.mobileNumber.data} onChange={(e) => setFormData({ ...formData, mobileNumber: { ...formData.mobileNumber, data: e.target.value } })} />
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <InputLabel sx={labelStyle}> Email address </InputLabel>
                    <InputBase sx={inputStyle} value={formData.email.data} onChange={(e) => setFormData({ ...formData, email: { ...formData.email, data: e.target.value } })} />
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={4}>
                    <InputLabel sx={labelStyle}> Occupation</InputLabel>
                    <InputBase sx={inputStyle} value={formData.occupation.data} onChange={(e) => setFormData({ ...formData, occupation: { ...formData.occupation, data: e.target.value } })} />
                </Grid>
                <Grid item xs={4}>
                    <InputLabel sx={labelStyle}> Employer </InputLabel>
                    <InputBase sx={inputStyle} value={formData.employer.data} onChange={(e) => setFormData({ ...formData, employer: { ...formData.employer, data: e.target.value } })} />
                </Grid>
                <Grid item xs={4}>
                    <InputLabel sx={labelStyle}> Work Phone Number </InputLabel>
                    <InputBase sx={inputStyle} value={formData.workPhone.data} onChange={(e) => setFormData({ ...formData, workPhone: { ...formData.workPhone, data: e.target.value } })} />
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={4}>
                    <InputLabel sx={labelStyle}> NHI Number</InputLabel>
                    <InputBase sx={inputStyle} value={formData.NHINumber.data} onChange={(e) => setFormData({ ...formData, NHINumber: { ...formData.NHINumber, data: e.target.value } })} />
                </Grid>
            </Grid>
            <Divider light />
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1, marginTop: 2 }}>
                <Grid item xs={4}>
                    <InputLabel sx={labelStyle}> Smoking Status</InputLabel>
                    <Select sx={selectStyle} value={formData.smokingStatus.data} onChange={(e) => setFormData({ ...formData, smokingStatus: { ...formData.smokingStatus, data: e.target.value } })}>
                        <MenuItem value={'Never smoked'}>Never smoked</MenuItem>
                        <MenuItem value={'Ex smoker'}>Ex smoker</MenuItem>
                        <MenuItem value={'Current smoker'}>Current Smoker, cigarettes or vaping.</MenuItem>
                    </Select>
                </Grid>
                {
                    formData.smokingStatus.data === 'Current smoker' ?
                        <Grid item xs={4}>
                            <InputLabel sx={labelStyle}>Do you want assistance to quit?</InputLabel>
                            <Select sx={selectStyle} value={formData.smokingStatusQuit.data} onChange={(e) => setFormData({ ...formData, smokingStatusQuit: { ...formData.smokingStatusQuit, data: e.target.value } })}>
                                <MenuItem value={'Yes'}>Yes</MenuItem>
                                <MenuItem value={'No'}>No</MenuItem>
                            </Select>
                        </Grid> : null
                }
                {
                    formData.smokingStatus.data === 'Ex smoker' ?
                        <Grid item xs={4}>
                            <InputLabel sx={labelStyle}>How long did you smoke for?</InputLabel>
                            <InputBase sx={inputStyle} value={formData.smokingStatusTime.data} onChange={(e) => setFormData({ ...formData, smokingStatusTime: { ...formData.smokingStatusTime, data: e.target.value } })} />
                        </Grid> : null
                }
                {
                    formData.smokingStatus.data === 'Ex smoker' ?
                        <Grid item xs={4}>
                            <InputLabel sx={labelStyle}>What year did you stop?</InputLabel>
                            <InputBase sx={inputStyle} value={formData.smokingStatusStop.data} onChange={(e) => setFormData({ ...formData, smokingStatusStop: { ...formData.smokingStatusStop, data: e.target.value } })} />
                        </Grid> : null
                }
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                <Button size='large' variant='contained' onClick={() => validation(1)}>Next</Button>
            </Box>

        </Box>
    )

}