import React, { useState, useEffect } from 'react';
import { InputBase, Divider, InputLabel, Grid, MenuItem, Select, Typography, Button, Alert } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';

const inputStyle = {
    border: '1px solid #C4C4C4',
    borderRadius: 3,
    height: '45px',
    width: '100%',
    paddingLeft: 2,
}

const selectStyle = {
    borderColor: '#C4C4C4',
    borderRadius: 3,
    height: '45px',
    width: '100%'
}

const labelStyle = {
    fontWeight: 'bold',
}

const dateStyle = {
    borderColor: '#C4C4C4',
    borderRadius: 3,
    height: '45px !important',
    width: '100%'
}

export const Ancestry = ({ setFormData, formData, validation, back }) => {

    return (
        <Box sx={{ marginTop: 3, }}>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle} >Country and place of birth (where were you born?):</InputLabel>
                    <InputBase sx={inputStyle} value={formData.countryBirth.data} onChange={(e) => setFormData({ ...formData, countryBirth: { ...formData.countryBirth, data: e.target.value } })} />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle}> Are you a New Zealand citizen? </InputLabel>
                    <Select sx={selectStyle} value={formData.nzCitizen.data} onChange={(e) => setFormData({ ...formData, nzCitizen: { ...formData.nzCitizen, data: e.target.value } })} >
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <InputLabel sx={labelStyle}> Are you living in New Zealand on a permanent or long term basis i.e, more than 2 years. ? </InputLabel>
                    <Select sx={selectStyle} value={formData.nzPermanent.data} onChange={(e) => setFormData({ ...formData, nzPermanent: { ...formData.nzPermanent, data: e.target.value } })} >
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Divider />
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle}> Ethnicity (Please select all that apply) </InputLabel>
                    <Select multiple sx={selectStyle} value={formData.ethnicity.data} onChange={(e) => setFormData({ ...formData, ethnicity: { ...formData.ethnicity, data: e.target.value } })} >
                        <MenuItem value={'NZEuropeanPakeha'}>NZ European/Pakeha</MenuItem>
                        <MenuItem value={'OtherEuropean'}>Other European</MenuItem>
                        <MenuItem value={'NewZealandMaori'}>New Zealand Maori</MenuItem>
                        <MenuItem value={'Samoan'}>Samoan</MenuItem>
                        <MenuItem value={'CookIslandMaori'}>Cook Island Maori</MenuItem>
                        <MenuItem value={'Tongan'}>Tongan</MenuItem>
                        <MenuItem value={'Niuean'}>Niuean</MenuItem>
                        <MenuItem value={'Tokelauan'}>Tokelauan</MenuItem>
                        <MenuItem value={'Fijian'}>Fijian</MenuItem>
                        <MenuItem value={'OtherPacificIsland'}>Other Pacific Island</MenuItem>
                        <MenuItem value={'SoutheastAsian'}>Southeast Asian</MenuItem>
                        <MenuItem value={'Chinese'}>Chinese</MenuItem>
                        <MenuItem value={'Indian'}>Indian</MenuItem>
                        <MenuItem value={'OtherAsian'}>Other Asian</MenuItem>
                        <MenuItem value={'MiddleEastern'}>Middle Eastern</MenuItem>
                        <MenuItem value={'LatinAmericanHispanic'}>Latin American/Hispanic</MenuItem>
                        <MenuItem value={'African'}>African</MenuItem>
                        <MenuItem value={'Other'}>Other</MenuItem>
                    </Select>
                </Grid>
                {
                    _.includes(formData.ethnicity.data, 'Other') ? <Grid item xs={6}>
                        <InputLabel sx={labelStyle} >Please specify:</InputLabel>
                        <InputBase sx={inputStyle} value={formData.otherEthnicity.data} onChange={(e) => setFormData({ ...formData, otherEthnicity: { ...formData.otherEthnicity, data: e.target.value } })} />
                    </Grid> : null
                }
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle}> Do you have any Maori Ancestry? </InputLabel>
                    <Select sx={selectStyle} value={formData.maoriAncestry.data} onChange={(e) => setFormData({ ...formData, maoriAncestry: { ...formData.maoriAncestry, data: e.target.value } })} >
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle}> Do you require an interpreter? </InputLabel>
                    <Select sx={selectStyle} value={formData.requireInterpreter.data} onChange={(e) => setFormData({ ...formData, requireInterpreter: { ...formData.requireInterpreter, data: e.target.value } })} >
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                </Grid>
                {
                    formData.requireInterpreter.data === 'Yes' ? <Grid item xs={6}>
                        <InputLabel sx={labelStyle} >Please specify which language:</InputLabel>
                        <InputBase sx={inputStyle} value={formData.interpreterLanguage.data} onChange={(e) => setFormData({ ...formData, interpreterLanguage: { ...formData.interpreterLanguage, data: e.target.value } })} />
                    </Grid> : null
                }
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle}> Do you have difficulty with (please select all that apply) </InputLabel>
                    <Select multiple sx={selectStyle} value={formData.difficultyWith.data} onChange={(e) => setFormData({ ...formData, difficultyWith: { ...formData.difficultyWith, data: e.target.value } })} >
                        <MenuItem value={'Reading'}>Reading</MenuItem>
                        <MenuItem value={'Writing'}>Writing</MenuItem>
                        <MenuItem value={'Memory'}>Memory</MenuItem>
                        <MenuItem value={'Hearing'}>Hearing</MenuItem>
                        <MenuItem value={'Vision'}>Vision</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle}> Do you have difficulty understanding aspects of your health? </InputLabel>
                    <Select sx={selectStyle} value={formData.understandingHealth.data} onChange={(e) => setFormData({ ...formData, understandingHealth: { ...formData.understandingHealth, data: e.target.value } })} >
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                <Button size='large' variant='text' onClick={() => back()}>Go back</Button>
                <Button size='large' variant='contained' onClick={() => validation(3)}>Next</Button>
            </Box>

        </Box>
    )
}