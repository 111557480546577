import React, { useState, useEffect } from 'react';
import { InputBase, Divider, InputLabel, Grid, MenuItem, Select, Typography, Button, Alert, TextField } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import { Dropzone, FileItem } from "@dropzone-ui/react";


const inputStyle = {
    border: '1px solid #C4C4C4',
    borderRadius: 3,
    height: '45px',
    width: '100%',
    paddingLeft: 2,
}

const selectStyle = {
    borderColor: '#C4C4C4',
    borderRadius: 3,
    height: '45px',
    width: '100%'
}

const labelStyle = {
    fontWeight: 'bold',
}


export const Identity = ({ setFormData, formData, validation, back }) => {
  

    const updateFiles = (incommingFiles) => {
        setFormData({...formData, files: {...formData.files, data: [...incommingFiles]}})

      };

    return (
        <Box sx={{ marginTop: 3}}>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <InputLabel sx={labelStyle}>Do you have a community Services Card (CSC) or High user Health Card (HUHC)? </InputLabel>
                    <Select sx={selectStyle} value={formData.specialCard.data} onChange={(e) => setFormData({ ...formData, specialCard: { ...formData.specialCard, data: e.target.value } })} >
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            {
                formData.specialCard.data === 'Yes' ?
                    <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                        <Grid item xs={6}>
                            <InputLabel sx={labelStyle} > CSC Number </InputLabel>
                            <InputBase sx={inputStyle} value={formData.CSC.data} onChange={(e) => setFormData({ ...formData, CSC: { ...formData.CSC, data: e.target.value } })} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel sx={labelStyle}>CSC Expiry</InputLabel>
                            <LocalizationProvider dateAdapter={AdapterLuxon} locale={"en-NZ"}>
                                <DatePicker
                                    locale
                                    value={formData.CSCExpire.data}
                                    onChange={(e) => setFormData({ ...formData, CSCExpire: { ...formData.CSCExpire, data: e } })}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid> : null
            }
            {
                formData.specialCard.data === 'Yes' ?
                    <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                        <Grid item xs={6}>
                            <InputLabel sx={labelStyle} > HUHC Number </InputLabel>
                            <InputBase sx={inputStyle} value={formData.HUHC.data} onChange={(e) => setFormData({ ...formData, HUHC: { ...formData.HUHC, data: e.target.value } })} />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel sx={labelStyle}>HSC Expiry</InputLabel>
                            <LocalizationProvider dateAdapter={AdapterLuxon} locale={"en-NZ"}>
                                <DatePicker
                                    locale
                                    value={formData.HUHCExpire.data}
                                    onChange={(e) => setFormData({ ...formData, HUHCExpire: { ...formData.HUHCExpire, data: e } })}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid> : null
            }
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <InputLabel sx={labelStyle}>Upload photo ID (NZ Drivers license or Passport)</InputLabel>
                    <Dropzone onChange={updateFiles} value={formData.files.data} accept={'.jpg, .PNG, .png, .jpeg'} maxFiles={4} maxFileSize={5000*1050}>
                        {formData.files.data.map((file) => (
                            <FileItem {...file} preview />
                        ))}
                    </Dropzone>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                <Button size='large' variant='text' onClick={() => back()}>Go back</Button>
                <Button size='large' variant='contained' onClick={() => validation(4)}>Next</Button>
            </Box>

        </Box>
    )
}