import React, { useState, useEffect } from 'react';
import { Button, Alert } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';


export const Confirm = ({ setFormData, formData, validation, back }) => {

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const submit = async () => {
        setError(false);
        setLoading(true);
        try {
            const result = await axios.post('/api/submit', { ...formData })
            if (result.status === 201) {
                setLoading(false)
                setSuccess(true)
            } else {
                setLoading(false)
                setError(true);
            }
        } catch (ex) {
            console.log(ex);
            setLoading(false)
            setError(true);
        }
    }

    return (
        <Box sx={{ marginTop: 3, }}>
            {!error ? <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Alert severity='success'>{success ? 'Your application has been submitted, for any questions please call 03 689 8016' : 'Please confirm you wish to submit this form.'}</Alert>
            </Box> : null}
            {error ? <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Alert severity='error'>Something went wrong, please try again. If you continue to have a problem please call 03 689 8016</Alert>
            </Box> : null }
            {success ? null : <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                <Button size='large' variant='text' onClick={() => back()}>Go back</Button>
                <LoadingButton size='large' variant='contained' loading={loading} onClick={() => submit()}>Submit</LoadingButton>
            </Box>}

        </Box>
    )
}