import React, { useState, useEffect } from 'react';
import { InputBase, Divider, InputLabel, Grid, MenuItem, Select, Typography, Button, Alert } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';

const inputStyle = {
    border: '1px solid #C4C4C4',
    borderRadius: 3,
    height: '45px',
    width: '100%',
    paddingLeft: 2,
}

const selectStyle = {
    borderColor: '#C4C4C4',
    borderRadius: 3,
    height: '45px',
    width: '100%'
}

const labelStyle = {
    fontWeight: 'bold',
}

const dateStyle = {
    borderColor: '#C4C4C4',
    borderRadius: 3,
    height: '45px !important',
    width: '100%'
}

export const Communication = ({ setFormData, formData, validation, back }) => {
    const [errorMessage, setErrorMessage] = useState(null);

    return (
        <Box sx={{ marginTop: 3, width: '100%' }}>
            {errorMessage ? <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, marginBottom: 3 }}><Alert severity="error">{errorMessage}</Alert></Box> : null}
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={8}>
                    <InputLabel sx={labelStyle}>Text Messaging</InputLabel>
                    <Typography >Do you consent to us contacting you by text messaging?</Typography >
                    <Typography >This may include appointment reminders and some test results.</Typography >
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Select sx={selectStyle} value={formData.textMessageConsent.data} onChange={(e) => setFormData({ ...formData, textMessageConsent: { ...formData.textMessageConsent, data: e.target.value } })}>
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={8}>
                    <InputLabel sx={labelStyle}>Manage My Health</InputLabel>
                    <Typography >Do you consent to using MMH Patient Portal?</Typography >
                    <Typography >More information is available at reception, call 03 689 8016</Typography >
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Select sx={selectStyle} value={formData.MMHConsent.data} onChange={(e) => setFormData({ ...formData, MMHConsent: { ...formData.MMHConsent, data: e.target.value } })}>
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={8}>
                    <InputLabel sx={labelStyle}>Email Messaging</InputLabel>
                    <Typography >Do you consent to us contacting you by email?</Typography >
                    <Typography >This may include appointment reminders and some test results.</Typography >
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Select sx={selectStyle} value={formData.emailMessageConsent.data} onChange={(e) => setFormData({ ...formData, emailMessageConsent: { ...formData.emailMessageConsent, data: e.target.value } })}>
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={8}>
                    <InputLabel sx={labelStyle}>Patient Survey</InputLabel>
                    <Typography >From time to time we may contact you and ask for your feedback on your experience of care. This provides important information which we use to improve health services. Participation is voluntary and anonymous.</Typography >
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Select sx={selectStyle} value={formData.patientSurveyConsent.data} onChange={(e) => setFormData({ ...formData, patientSurveyConsent: { ...formData.patientSurveyConsent, data: e.target.value } })}>
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Divider light />
            <Typography variant="h6" component="div" mt={2} mb={2}>
                Emergency Contact
            </Typography>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle} > Full name </InputLabel>
                    <InputBase sx={inputStyle} value={formData.emergencyFullName.data} onChange={(e) => setFormData({ ...formData, emergencyFullName: { ...formData.emergencyFullName, data: e.target.value } })} />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle} > Home address </InputLabel>
                    <InputBase sx={inputStyle} value={formData.emergencyAddress.data} onChange={(e) => setFormData({ ...formData, emergencyAddress: { ...formData.emergencyAddress, data: e.target.value } })} />
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle} > Phone number </InputLabel>
                    <InputBase sx={inputStyle} value={formData.emergencyNumber.data} onChange={(e) => setFormData({ ...formData, emergencyNumber: { ...formData.emergencyNumber, data: e.target.value } })} />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle} > Relationship to patient </InputLabel>
                    <InputBase sx={inputStyle} value={formData.emergencyRelationship.data} onChange={(e) => setFormData({ ...formData, emergencyRelationship: { ...formData.emergencyRelationship, data: e.target.value } })} />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                <Button size='large' variant='text' onClick={() => back()}>Go back</Button>
                <Button size='large' variant='contained' onClick={() => validation(2)}>Next</Button>
            </Box>

        </Box>
    )
}