import React, { useRef } from 'react';
import { InputBase, InputLabel, Grid, MenuItem, Select, TextareaAutosize, TextField, Button, Divider, Radio, Typography, RadioGroup, FormControlLabel, Container } from '@mui/material';
import { Box } from '@mui/system';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { LocalizationProvider, DatePicker } from '@mui/lab';
import SignaturePad from 'react-signature-pad-wrapper';



const inputStyle = {
    border: '1px solid #C4C4C4',
    borderRadius: 3,
    height: '45px',
    width: '100%',
    paddingLeft: 2,
}

const selectStyle = {
    borderColor: '#C4C4C4',
    borderRadius: 3,
    height: '45px',
    width: '100%'
}

const labelStyle = {
    fontWeight: 'bold',
}

const dateStyle = {
    borderColor: '#C4C4C4',
    borderRadius: 3,
    height: '45px !important',
    width: '100%'
}



export const Terms = ({ setFormData, formData, validation, back }) => {

    const signaturePadRef = useRef(null);

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const saveSig = async () => {
        const sigPad = signaturePadRef.current;
        if (!sigPad.isEmpty()) {
            const sig = await sigPad.toDataURL();
            setFormData({ ...formData, signature: { ...formData.signature, data: sig } });
        }

    }


    const save = async () => {
        let files = []
        for (const f of formData.files.data) {
            let base64 = await toBase64(f.file);
            files.push(base64)
        }
        setFormData({ ...formData, files: { ...formData.files, data64: [...files] } });
        validation(5);
    }

    const clear = () => {
        const sigPad = signaturePadRef.current;
        if (!sigPad.isEmpty()) {
            sigPad.clear();

        }
        setFormData({ ...formData, signature: { ...formData.signature, data: '' } })
    }

    return (
        <Box sx={{ marginTop: 3 }}>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <InputLabel sx={labelStyle}>Eligibility for Funded General Practice Services</InputLabel>
                    <Typography>I intend to use the Waimate Medical Centre as my regular and on-going provider of general practice services.</Typography>
                    <Typography>1.	I am eligible to enrol because I live in New Zealand and meet one of the following criteria (please tick where appropriate):</Typography>
                    <RadioGroup name="use-radio-group" defaultValue={formData.eligibility.data} sx={{ padding: 2 }} onChange={(e) => setFormData({ ...formData, eligibility: { ...formData.eligibility, data: e.target.value } })}>
                        <Grid container direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                            <Grid item xs={1}><FormControlLabel value="NZCitizen" label={''} control={<Radio />} /></Grid>
                            <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }}><Typography>I am a New Zealand citizen; OR</Typography></Grid>
                        </Grid>
                        <Grid container direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                            <Grid item xs={1}><FormControlLabel value="NZResident" label={''} control={<Radio />} /></Grid>
                            <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}><Typography>New Zealand Resident</Typography>
                                <Typography>a)	Hold a resident visa or a permanent resident visa and intend to stay in New Zealand for a minimum of 2 years; OR</Typography>
                                <Typography>b)	Australian citizen or permanent resident and able to show intent to stay in New Zealand for at least 2 years; OR</Typography>
                                <Typography>c)	Work visa/permit holder and can show that I am able to be in New Zealand for at least 2 years; OR</Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                            <Grid item xs={1}><FormControlLabel value="Refugee" label={''} control={<Radio />} /></Grid>
                            <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }}><Typography>Refugee or protected person or in the process of applying for, or appealing refugee or protection status or a victim or suspected victim of people trafficking;  OR</Typography></Grid>
                        </Grid>
                        <Grid container direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                            <Grid item xs={1} ><FormControlLabel value="Under18" label={''} control={<Radio />} /></Grid>
                            <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }}><Typography>Under 18 years and in the care and control of a parent/legal guardian/adopting parent who is a New Zealand Citizen or New Zealand Resident;  OR</Typography></Grid>
                        </Grid>
                        <Grid container direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                            <Grid item xs={1}><FormControlLabel value="EligibleOther" label={''} control={<Radio />} /></Grid>
                            <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }}><Typography>Other (Please provide details of entitlement including relevant permit and dates)</Typography></Grid>
                        </Grid>
                    </RadioGroup>
                </Grid>
            </Grid>
            {
                formData.eligibility.data === 'Other' ? <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                    <Grid item xs={6}>
                        <InputLabel sx={labelStyle}>Please specify:</InputLabel>
                        <TextareaAutosize minRows={5} style={{ width: 500 }} sx={inputStyle} value={formData.eligibilityOther.data} onChange={(e) => setFormData({ ...formData, eligibilityOther: { ...formData.eligibilityOther, data: e.target.value } })} />
                    </Grid>
                </Grid> : null
            }
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <InputLabel sx={labelStyle}>2.	I confirm that, if requested, I can provide proof of my eligibility </InputLabel>
                    <Select sx={selectStyle} value={formData.confirmEligibility.data} onChange={(e) => setFormData({ ...formData, confirmEligibility: { ...formData.confirmEligibility, data: e.target.value } })} >
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Divider />
            <Typography variant="subtitle1" component="div" mt={2} mb={2}>
                Transfer of Records from Previous Medical Centre
            </Typography>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>

                <Grid item xs={6}>
                    <InputLabel sx={labelStyle} > Name of Previous Medical Centre </InputLabel>
                    <InputBase sx={inputStyle} value={formData.previousMedicalName.data} onChange={(e) => setFormData({ ...formData, previousMedicalName: { ...formData.previousMedicalName, data: e.target.value } })} />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel sx={labelStyle} > Name of your GP at your previous medical centre:  </InputLabel>
                    <InputBase sx={inputStyle} value={formData.namePreviousGP.data} onChange={(e) => setFormData({ ...formData, namePreviousGP: { ...formData.namePreviousGP, data: e.target.value } })} />
                </Grid>
            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <InputLabel sx={labelStyle}>I authorise the Waimate Medical Centre to obtain my medical records from my previous General Practitioner </InputLabel>
                    <Select sx={selectStyle} value={formData.authoriseMedicalRecords.data} onChange={(e) => setFormData({ ...formData, authoriseMedicalRecords: { ...formData.authoriseMedicalRecords, data: e.target.value } })} >
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Container sx={{ marginTop: 1 }}>
                <Typography variant='h6'>Terms of Business:</Typography>
                <Typography sx={{ marginBottom: 1 }}>By signing this form you signify that you have read these terms, and agree to all of them. </Typography>
                <Typography sx={{ marginBottom: 1 }}>1.	I intend to use this practice as my preferred and long-term provider of general practice services.</Typography>
                <Typography sx={{ marginBottom: 1 }}>2.	I agree to the statement below regarding my health information.</Typography>
                <Typography sx={{ marginBottom: 1 }}>3.	I authorise my previous medical centre to inform the Waimate Medical Centre of any unpaid debt that I may have with them. </Typography>
                <Typography sx={{ marginBottom: 1 }}>4.	I acknowledge that the Waimate Medical Centre may choose to decline my enrolment if I have a debt with my previous medical centre. </Typography>
                <Typography sx={{ marginBottom: 1 }}>5.	I agree that payment is required at the time of my consultation for all services provided to me by Waimate Medical Centre.</Typography>
                <Typography sx={{ marginBottom: 1 }}>6.	I agree that unpaid accounts may be passed on to a debt collection agency, and that any fees incurred in the collection of overdue accounts are payable by me (the debtor).</Typography>
                <Typography sx={{ marginBottom: 1 }}>7.	I agree that upon my enrolment I may be required to have a consultation with a practitioner, either at the Medical Centre or over the telephone, to update my medical history etc.  The consult may incur the usual fees.</Typography>
            </Container>
            <Container sx={{ marginBottom: 1 }}>
                <Typography variant='h6'>
                    Health information privacy statement:
                </Typography>
                <Typography sx={{ marginBottom: 1 }}>All information provided to Waimate Medical Centre is protected by the medical ethics of confidentiality, the provisions of the Privacy Act 2020 and the Health Information Privacy Code 2020.</Typography>
                <Typography sx={{ marginBottom: 1 }}>The information you give will be held by Waimate Medical Centre, used by the Ministry of Health to update any changes, sent to the PHO and Ministry of Health to get subsidised funding on your behalf, and used to determine eligibility to funded services. At the medical centre, members of the health team may add to your health record during any services provided to you and use that information to provide appropriate care. They may also send relevant health information to other health professionals, such as other doctors, agencies or hospitals, who are involved in your care</Typography>
                <Typography sx={{ marginBottom: 1, fontWeight: 'bold' }}>By completing and signing this form, I agree that I have read, and understood, the conditions of enrolment above. I further agree that payment will be made at the time of my consultation, and that should payment not be made then the account may be sent to a debt collector, and that collection fees will apply. </Typography>
            </Container >
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={6} md={6} lg={6} xl={6}>
                    <InputLabel sx={labelStyle}>Name</InputLabel>
                    <InputBase sx={inputStyle} value={formData.signatureName.data} onChange={(e) => setFormData({ ...formData, signatureName: { ...formData.signatureName, data: e.target.value } })} />
                    <Typography variant='caption'>NB: Parent or Caregiver to sign if under 16 years of age (please state name and relationship). </Typography>
                </Grid>

                <Grid item xs={6} md={6} lg={6} xl={6}>
                    <InputLabel sx={labelStyle}>Date</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterLuxon} locale={"en-NZ"}>
                        <DatePicker
                            disabled={true}
                            locale
                            onChange={() => null}
                            value={formData.signatureDate.data}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                </Grid>
                <Grid item xs={8}>
                    <InputLabel sx={labelStyle}>If signing on behalf of patient please state your name & relationship.</InputLabel>
                    <InputBase sx={inputStyle} value={formData.signatureRelationship.data} onChange={(e) => setFormData({ ...formData, signatureRelationship: { ...formData.signatureRelationship, data: e.target.value } })} />
                </Grid>

            </Grid>
            <Grid container direction="row" spacing={2} sx={{ marginBottom: 1 }}>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                    <InputLabel sx={labelStyle}>Signature (please draw)</InputLabel>
                    <Box sx={{ border: '1px solid #333' }}>
                        <SignaturePad redrawOnResize ref={signaturePadRef} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={() => clear()}>clear</Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                    {formData.signature.data ? <InputLabel sx={labelStyle}>Signature (saved)</InputLabel> : null}
                    {formData.signature.data ? <Box sx={{ border: '1px solid #333' }}>
                        <img src={formData.signature.data} alt='saved signature' />
                    </Box> : null}
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
                <Button size='large' variant='text' onClick={() => back()}>Go back</Button>
                <Button size='large' variant='contained' onClick={() => !formData.signature.data ? saveSig() : save()}>{!formData.signature.data ? 'Save Signature' : 'NEXT'}</Button>
            </Box>

        </Box>
    )

}